$(document).ready(function(){
    // Hover in der Werke Übersicht
    $('#portfolio .portfolio-item').hover(
        function(){
            $(this).find('.portfolio-caption-wrapper').fadeIn(500); //.fadeIn(250)
        },
        function(){
            $(this).find('.portfolio-caption-wrapper').fadeOut(500); //.fadeOut(205)
        }
    );
});
