/* -----------------------------------------------------------------------------

  IMPORT GLOBAL NODE MODULES

----------------------------------------------------------------------------- */

// Import What-Input for Foundation
import whatInput from 'what-input';

// Import Foundation Framework
import Foundation from 'foundation-sites';
// Foundation.addToJquery($);


// /* -----------------------------------------------------------------------------
//
//   IMPORT CUSTOM MODULES
//
// ----------------------------------------------------------------------------- */
//

// import Setheight from './modules/setheight';
import Portfolio from './modules/portfolio';


// /* -----------------------------------------------------------------------------
//
//   ADD OBJECTS TO GLOBAL NAMESPACE
//
// ----------------------------------------------------------------------------- */

global.$ = global.jQuery = $;


// /* -----------------------------------------------------------------------------
//
//   EXECUTE FUNCTIONS
//
// ----------------------------------------------------------------------------- */

$(document).foundation();
